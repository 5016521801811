import http from '@/config/axios.config'

// 得到所有图层统计数量
export function getLayersCount() {
    return http({
        url: `/rest/betaGISUI/home/count/layers`,
        method: "get",
    })
}

// 得到各工作区下所有图层统计数量
export function getLayersCountOrderByWorkspace() {
    return http({
        url: `/rest/betaGISUI/home/count/layersOrderByWorkspace`,
        method: "get",
    })
}

// 得到数据模块的统计信息
export function getDataModuleStatistics() {
    return http({
        url: `/rest/betaGISUI/home/count/dataModuleStatistics`,
        method: "get",
    })
}

// 得到系统信息
export function getSystemStatus() {
    return http({
        url: `/rest/about/system-status`,
        method: "get",
    })
}