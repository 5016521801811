<template>
  <div class="home">
    <div class="actions">
      <p class="text"></p>
      <a-button type="primary" class="add-data-store" @click="addDataStore"
        >添加数据存储</a-button
      >
      <a-button type="primary" class="add-layer" @click="addLayer"
        >创建图层</a-button
      >
      <a-button type="primary" class="show-all-layer" @click="showAllLayer"
        >查看所有图层</a-button
      >
    </div>
    <div class="mainInfo">
      <div class="leftInfo">
        <div id="allLayers"></div>
        <div id="workspaces"></div>
      </div>
      <div class="visitRank">
        <div>访问图层 (TOP: 10)</div>
        <a-progress
          :percent="92"
          :show-info="true"
          :format="(percent) => percent"
          class="eachVisitData"
        />
        <a-progress
          :percent="86"
          :show-info="true"
          :format="(percent) => percent"
          class="eachVisitData"
        />
        <a-progress
          :percent="81"
          :show-info="true"
          :format="(percent) => percent"
          class="eachVisitData"
        />
        <a-progress
          :percent="73"
          :show-info="true"
          :format="(percent) => percent"
          class="eachVisitData"
        />
        <a-progress
          :percent="65"
          :show-info="true"
          :format="(percent) => percent"
          class="eachVisitData"
        />
        <a-progress
          :percent="59"
          :show-info="true"
          :format="(percent) => percent"
          class="eachVisitData"
        />
        <a-progress
          :percent="41"
          :show-info="true"
          :format="(percent) => percent"
          class="eachVisitData"
        />
        <a-progress
          :percent="33"
          :show-info="true"
          :format="(percent) => percent"
          class="eachVisitData"
        />
        <a-progress
          :percent="27"
          :show-info="true"
          :format="(percent) => percent"
          class="eachVisitData"
        />
      </div>
    </div>
    <div class="otherInfo">
      <div id="countInfo"></div>
      <div id="memoryUsageInfo"></div>
      <div id="diskUsageInfo"></div>
    </div>
    <div class="message"></div>
    <div class="footer">
      <div>©2018-2021 西安笛卡尔信息技术有限公司</div>
    </div>
  </div>
</template>

<script>
import {
  getLayersCount,
  getLayersCountOrderByWorkspace,
  getDataModuleStatistics,
  getSystemStatus,
} from "@/api/home/home.js";
export default {
  data() {
    return {};
  },
  mounted() {
    this.echartsInit();
  },
  methods: {
    addDataStore() {
      this.$router.push({ path: "../data/storage", query: { add: true } });
    },
    addLayer() {
      this.$router.push({ path: "../data/layers", query: { add: true } });
    },
    showAllLayer() {
      this.$router.push({ path: "../data/layers" });
    },
    echartsInit() {
      getLayersCount().then((res) => {
        let publishedVectorLayerCount = 0;
        let publishedRasterLayerCount = 0;
        let unPublishedVectorLayerCount = 0;
        let unPublishedRasterLayerCount = 0;
        if (res.status == 200) {
          publishedVectorLayerCount = res.data.publishedVectorLayerCount;
          publishedRasterLayerCount = res.data.publishedRasterLayerCount;
          unPublishedVectorLayerCount = res.data.unPublishedVectorLayerCount;
          unPublishedRasterLayerCount = res.data.unPublishedRasterLayerCount;
        }
        if(publishedVectorLayerCount==0) publishedVectorLayerCount='';
        if(publishedRasterLayerCount==0) publishedRasterLayerCount='';
        if(unPublishedVectorLayerCount==0) unPublishedVectorLayerCount='';
        if(unPublishedRasterLayerCount==0) unPublishedRasterLayerCount='';
        this.$echarts.init(document.getElementById("allLayers")).setOption({
          title: {
            text: `所有图层数量: ${
              publishedVectorLayerCount +
              publishedRasterLayerCount +
              unPublishedVectorLayerCount +
              unPublishedRasterLayerCount
            }`,
            left: "left",
            textStyle: {
              color: "#282828",
              fontSize: "14px",
              fontFamily: "PingFang SC",
              fontWeight: 500,
              lineHeight: 20,
            },
          },
          legend: {
            type: "scroll",
            orient: "vertical",
            right: 10,
            top: "center",
            data: [
              "已发布矢量图层数量",
              "已发布删格图层数量",
              "未发布矢量图层数量",
              "未发布删格图层数量",
            ],
          },
          series: [
            {
              name: "半径模式",
              type: "pie",
              radius: [40, 90],
              center: ["50%", "50%"],
              data: [
                {
                  value: publishedVectorLayerCount,
                  name: "已发布矢量图层数量",
                  itemStyle: {
                    color: "#2093F7",
                  },
                },
                {
                  value: publishedRasterLayerCount,
                  name: "已发布删格图层数量",
                  itemStyle: {
                    color: "#13CF5A",
                  },
                },
                {
                  value: unPublishedVectorLayerCount,
                  name: "未发布矢量图层数量",
                  itemStyle: {
                    color: "#FF3844",
                  },
                },
                {
                  value: unPublishedRasterLayerCount,
                  name: "未发布删格图层数量",
                  itemStyle: {
                    color: "#FFBC20",
                  },
                },
              ].sort(function (a, b) {
                return a.value - b.value;
              }),
              roseType: "radius",
              label: {
                formatter: "{c}",
                color: "#282828",
              },
              labelLine: {
                show: true,
                lineStyle: {
                  color: "#909090",
                },
                smooth: 0.2,
                length: 10,
                length2: 20,
              },
              itemStyle: {
                color: "#c23531",
              },
            },
          ],
        });
      });

      getLayersCountOrderByWorkspace().then((res) => {
        let sourceList = [
          { product: "工作区1", 已发布图层数量: 43.3, 未发布图层数量: 85.8 },
          { product: "工作区2", 已发布图层数量: 83.1, 未发布图层数量: 73.4 },
          { product: "工作区3", 已发布图层数量: 86.4, 未发布图层数量: 65.2 },
          { product: "工作区4", 已发布图层数量: 72.4, 未发布图层数量: 53.9 },
        ];
        if (res.status == 200) {
          sourceList = res.data.map((item) => {
            return {
              product: item.workspace,
              已发布图层数量: item.publishedLayerCount,
              未发布图层数量: item.unPublishedLayerCount,
            };
          });
        }

        this.$echarts.init(document.getElementById("workspaces")).setOption({
          title: {
            text: "工作区图层数量概览：",
            left: "left",
            textStyle: {
              color: "#282828",
              fontSize: "14px",
              fontFamily: "PingFang SC",
              fontWeight: 500,
              lineHeight: 20,
            },
          },
          legend: {},
          dataset: {
            dimensions: ["product", "已发布图层数量", "未发布图层数量"],
            source: sourceList,
          },
          xAxis: { type: "category" },
          yAxis: { minInterval: 1 },
          series: [
            {
              type: "bar",
              barWidth: 14,
              itemStyle: {
                color: "#2093F7",
                barBorderRadius: 7,
              },
            },
            {
              type: "bar",
              barWidth: 14,
              itemStyle: {
                color: "#FFBC20",
                barBorderRadius: 7,
              },
            },
          ],
        });
      });

      getDataModuleStatistics().then((res) => {
        let sourceList = [120, 200, 150, 80];
        if (res.status == 200) {
          sourceList = [
            res.data.workspaces,
            res.data.stores,
            res.data.layers,
            res.data.styles,
          ];
        }
        this.$echarts.init(document.getElementById("countInfo")).setOption({
          title: {
            text: "统计信息：",
            left: "left",
            textStyle: {
              color: "#282828",
              fontSize: "14px",
              fontFamily: "PingFang SC",
              fontWeight: 500,
              lineHeight: 20,
            },
          },
          xAxis: {
            type: "category",
            data: ["工作区数量", "数据存储量", "图层数量", "样式数量"],
            axisLabel: {
              // rotate: '45',
              interval: 0,
              color: "#767676",
              fontSize: 12,
              // x轴文本换行
              formatter: function (params) {
                var newParamsName = ""; // 最终拼接成的字符串
                var paramsNameNumber = params.length; // 实际标签的个数
                var provideNumber = 4; // 每行能显示的字的个数
                var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 换行的话，需要显示几行，向上取整
                // 条件等同于rowNumber>1
                if (paramsNameNumber > provideNumber) {
                  /** 循环每一行,p表示行 */
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = ""; // 表示每一次截取的字符串
                    var start = p * provideNumber; // 开始截取的位置
                    var end = start + provideNumber; // 结束截取的位置
                    // 此处特殊处理最后一行的索引值
                    if (p == rowNumber - 1) {
                      tempStr = params.substring(start, paramsNameNumber);
                    } else {
                      // 每一次拼接字符串并换行
                      tempStr = params.substring(start, end) + "\n";
                    }
                    newParamsName += tempStr; // 最终拼成的字符串
                  }
                } else {
                  // 将旧标签的值赋给新标签
                  newParamsName = params;
                }
                return newParamsName;
              },
            },
          },
          yAxis: {
            type: "value",
            minInterval: 1,
          },
          series: [
            {
              data: sourceList,
              type: "bar",
              barWidth: 14,
              color: ["#2093F7", "#13CF5A", "#FF3844", "#FFBC20"],
            },
          ],
        });
      });

      getSystemStatus().then((res) => {
        let MEMORY_TOTAL = 0;
        let MEMORY_FREE = 0;
        let PARTITION_TOTAL = 0;
        let PARTITION_FREE = 0;
        if (res.status == 200) {
          res.data.metrics.metric.map((item) => {
            if (item.name == "MEMORY_TOTAL") MEMORY_TOTAL = item.value;
            if (item.name == "MEMORY_FREE") MEMORY_FREE = item.value;
            if (item.name == "PARTITION_TOTAL") PARTITION_TOTAL = item.value;
            if (item.name == "PARTITION_FREE") PARTITION_FREE = item.value;
          });
        }
        this.$echarts
          .init(document.getElementById("memoryUsageInfo"))
          .setOption({
            title: {
              text: "内存使用情况：",
              left: "left",
              textStyle: {
                color: "#282828",
                fontSize: "14px",
                fontFamily: "PingFang SC",
                fontWeight: 500,
                lineHeight: 20,
              },
            },
            series: [
              {
                name: "内存使用情况",
                type: "pie",
                radius: "55%",
                center: ["50%", "60%"],
                data: [
                  {
                    value: MEMORY_FREE,
                    name: `剩余` + (MEMORY_FREE / 1000000000).toFixed(1) + `G`,
                    itemStyle: {
                      color: "#13CF5A",
                    },
                  },
                  {
                    value: MEMORY_TOTAL,
                    name: `全部` + (MEMORY_TOTAL / 1000000000).toFixed(1) + `G`,
                    itemStyle: {
                      color: "#2093F7",
                    },
                  },
                ],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0.5)",
                  },
                },
              },
            ],
          });

        this.$echarts.init(document.getElementById("diskUsageInfo")).setOption({
          title: {
            text: "分区使用情况：",
            left: "left",
            textStyle: {
              color: "#282828",
              fontSize: "14px",
              fontFamily: "PingFang SC",
              fontWeight: 500,
              lineHeight: 20,
            },
          },
          series: [
            {
              name: "分区使用情况",
              type: "pie",
              radius: "55%",
              center: ["50%", "60%"],
              data: [
                {
                  value: PARTITION_FREE,
                  name: `剩余` + (PARTITION_FREE / 1000000000).toFixed(1) + `G`,
                  itemStyle: { color: "#13CF5A" },
                },
                {
                  value: PARTITION_TOTAL,
                  name:
                    `全部` + (PARTITION_TOTAL / 1000000000).toFixed(1) + `G`,
                  itemStyle: { color: "#2093F7" },
                },
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        });
      });
    },
  },
};
</script>
<style scoped>
.home {
  position: absolute;
  top: 80px;
  right: 0;
  bottom: 0;
  left: 10px;
  overflow: auto;
}
.actions {
  width: 100%;
  height: 110px;
  background: #ffffff;
  border-radius: 4px;
}
.text {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #282828;
  line-height: 20px;
  padding-left: 16px;
  padding-top: 10px;
}
.add-data-store,
.add-layer,
.show-all-layer {
  width: 200px;
  height: 44px;
  border-radius: 8px;
  border: #ffffff;
  margin-left: 16px;
}
.add-data-store {
  background: #2093f7;
}
.add-layer {
  background: #ffbc20;
}
.show-all-layer {
  background: #13cf5a;
}
.mainInfo {
  display: flex;
  width: 100%;
  height: 630px;
  padding-top: 10px;
}
.leftInfo {
  width: 60%;
}
.visitRank {
  width: 40%;
  height: 600;
  padding: 10px 10px 0px 10px;
  margin: 10px 0px 0px 10px;
  background: #fff;
}
.eachVisitData {
  margin-top: 30px;
}
#allLayers {
  width: 100%;
  height: 300px;
  background: #fff;
  padding: 10px;
  margin: 10px 0px 10px 0px;
}
#workspaces {
  width: 100%;
  height: 300px;
  background: #fff;
  padding-top: 10px;
  margin-top: 10px 0px 10px 0px;
}
.otherInfo {
  display: flex;
  width: 100%;
  height: 300px;
  background: #fff;
  padding: 10px;
  margin: 10px 0px 0px 0px;
}
#countInfo,
#memoryUsageInfo,
#diskUsageInfo {
  width: 30%;
  height: 300px;
  margin-right: 4%;
}
.footer {
  text-align: center;
  margin: 10px;
}
</style>
